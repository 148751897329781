import React, { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";

import { VoiceList, VoiceData } from "~components/utils/voice-list";

type ContainerProps = {
  slugs?: string[];
};

type Props = {
  data: VoiceData[];
} & ContainerProps;

const Component = (props: Props) => {
  const { data, slugs } = props;
  return <VoiceList data={data} slugs={slugs} theme="KENRO" />;
};

export const KenroVoiceList = (props: ContainerProps) => {
  const response: GatsbyKenroVoiceListComponentQuery = useStaticQuery(graphql`
    query KenroVoiceListComponent {
      allContentfulVoice(filter: { category: { slug: { eq: "kenro" } } }) {
        nodes {
          ...VoiceQuery
        }
      }
    }
  `);

  const data = useMemo(() => {
    return response.allContentfulVoice.nodes.map((node) => {
      return {
        ...node,
        link: `/kenro/voice/${node.slug}`,
      };
    });
  }, [response]);

  return <Component {...props} data={data} />;
};
