import React from "react";
import { graphql, Link } from "gatsby";
import { Button } from "~components/utils/button";
import { MetaProps } from "~components/templates/seo";
import * as styles from "./index.module.scss";
import { Kenro2PaneLayout } from "~components/templates/kenro-2pane-layout/page";
import { KenroAchievements } from "~components/kenro/achievements";
import { KenroVoiceList } from "~components/kenro/voice-list";

type Props = {
  data: GatsbyKenroNewGradsTrainingPageQuery;
};

const Component: React.FC<Props & { className?: string }> = ({ ...props }) => {
  const { data } = props;
  const meta: MetaProps = {
    title:
      "KENRO (ケンロー) | 新卒エンジニアのための、楽しめるセキュリティ研修",
    image: `https://flatt.tech${data?.ogp?.publicURL || ""}`,
    description:
      "KENRO (ケンロー) は、楽しめる新卒エンジニア研修をかんたんに実現できる、セキュアコーディング学習プラットフォームです。",
    isSeparateSite: true,
    useTypeSquareFonts: true,
  };

  return (
    <Kenro2PaneLayout meta={meta}>
      <div className={styles.Summary}>
        <div className={styles.Summary_Container}>
          <div className={styles.Summary_Left}>
            <div>
              <h2 className={styles.Summary_Heading}>
                <span className={styles.Summary_Green}>新卒エンジニア</span>
                のための
                <br />
                楽しめるセキュリティ研修
              </h2>
              <div className={styles.Summary_Description}>
                Web開発におけるセキュリティ技術を豊富な実践演習で学べる、
                <br />
                セキュアコーディング学習プラットフォーム
              </div>
              <a href="/kenro/download/service">
                <Button
                  buttonTheme="Kenro_Download"
                  className={styles.Summary_Cta}
                  type="button"
                  buttonSize="Medium"
                >
                  資料をダウンロード (無料)
                </Button>
              </a>
            </div>
          </div>
          <div className={styles.Summary_Right}>
            <img
              className={styles.Summary_Mock}
              src={data?.firstview_mock?.publicURL || ""}
              alt="KENROのサービスイメージ"
            />
          </div>
        </div>
      </div>
      <div className={styles.Achievement}>
        <KenroAchievements type="new-grads-training" />
        <p className={styles.Achievement_Description}>
          ※新卒研修以外でのご利用実績も含みます。
        </p>
      </div>
      <div className={styles.KeyFeature}>
        <h2 className={styles.KeyFeature_Heading}>
          楽しんで学べるセキュアコーディング研修を、かんたんに実現
        </h2>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <div className={styles.KeyFeature_Container}>
            <div className={styles.KeyFeature_Item}>
              <div className={styles.KeyFeature_ItemIcons}>
                <img
                  className={styles.KeyFeature_Image}
                  src={data?.hacking_like_training?.publicURL || ""}
                  alt="ハッキング演習のイメージ"
                />
                <img
                  className={styles.KeyFeature_Image}
                  src={data?.defense_training?.publicURL || ""}
                  alt="堅牢化演習のイメージ"
                />
              </div>
              <div className={styles.KeyFeature_Description}>
                <h3 className={styles.KeyFeature_ItemHeading}>
                  脆弱なアプリケーションへの
                  <br />
                  攻撃と修正を体験できるプラットフォーム
                </h3>
                <p className={styles.KeyFeature_ItemText}>
                  CTF形式の攻撃演習と、ソースコード修正の体験を通じて、Webアプリケーションの開発現場に欠かせないセキュリティの知識を、楽しみながら体系的に学ぶことができます。
                </p>
              </div>
            </div>
            <div className={styles.KeyFeature_Item}>
              <div className={styles.KeyFeature_ItemIcons}>
                <img
                  className={styles.KeyFeature_Image}
                  src={data?.codespace?.publicURL || ""}
                  alt="演習環境構築不要のイメージ"
                />
                <img
                  className={styles.KeyFeature_Image}
                  src={data?.initial_cost_zero?.publicURL || ""}
                  alt="初期コストゼロのイメージ"
                />
              </div>
              <div className={styles.KeyFeature_Description}>
                <h3 className={styles.KeyFeature_ItemHeading}>
                  環境構築いらずで、初期費用ゼロ <br />
                  低コストで導入可能
                </h3>
                <p className={styles.KeyFeature_ItemText}>
                  受講者/管理者ともに演習環境の構築は不要です。また、最低1名から買い切りの料金体系で導入できるため、研修の規模に関わらず、低コストで導入が可能です。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.UseCase}>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <h2 className={styles.UseCase_Heading}>
            新卒研修でのKENROの活用方法
          </h2>
          <div className={styles.UseCase_HeadingDescription}>
            KENROは、
            <span className={styles.UseCase_Green}>
              オンラインでいつでも受講可能な20時間程度(※)の学習コンテンツです。
            </span>
            また、受講人数ごとの買い切りの料金設定
            となっているため、受講人数に応じて無駄なくお使いいただけます。
            <p className={styles.UseCase_Annotation}>
              ※基礎的な脆弱性に関するコンテンツに関しての受講時間の目安になります。
            </p>
          </div>
          <div className={styles.UseCase_Container}>
            <div className={styles.UseCase_Item}>
              <div className={styles.UseCase_Description}>
                <div className={styles.UseCase_ItemHeadingSubText}>CASE 1</div>
                <img
                  className={styles.UseCase_Image}
                  src={data?.education?.publicURL || ""}
                />
                <h3 className={styles.UseCase_ItemHeading}>
                  新卒研修の演習教材として受講
                </h3>
                <p className={styles.UseCase_ItemText}>
                  自社の研修講義の教材としての利用や、KENROに取り組む研修時間の設定など、柔軟に自社の新卒研修に組み込むことができます。
                </p>
              </div>
            </div>
            <div className={styles.UseCase_Item}>
              <div className={styles.UseCase_Description}>
                <div className={styles.UseCase_ItemHeadingSubText}>CASE 2</div>
                <img
                  className={styles.UseCase_Image}
                  src={data?.rocket?.publicURL || ""}
                />
                <h3 className={styles.UseCase_ItemHeading}>
                  配属後の業務と並行して受講
                </h3>
                <p className={styles.UseCase_ItemText}>
                  オンラインでいつでも受講できるため、業務時間の一部を使いながらでも受講をすることができます。また、受講状況は管理画面で簡単に確認できます。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Voice}>
        <div className={styles.Voice_Container}>
          <KenroVoiceList slugs={["cyberagent", "pixiv", "pepabo"]} />
        </div>
      </div>
      <div className={styles.Element}>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <h2 className={styles.Element_Heading}>
            KENROが新卒研修に選ばれる3つのポイント
          </h2>
          <div className={styles.Element_Item}>
            <img
              className={styles.Element_Image}
              src={data?.text?.publicURL || ""}
              alt="KENROのハンズオン演習のイメージ"
            />
            <div className={styles.Element_Text}>
              <div className={styles.Element_ItemHeadingNumber}>1</div>
              <h3 className={styles.Element_ItemHeading}>
                アプリケーションへの「攻撃」と「修正」を楽しんで学べる
              </h3>
              <p className={styles.Element_Description}>
                KENROは、受け身で学ぶサービスではありません。CTF形式の攻撃演習と、攻撃とソースコードの修正を、実際に手を動かして体験することで、
                <b>楽しんで学べることがKENROの強み</b>です。
              </p>
            </div>
          </div>
          <div className={styles.Element_ItemReverse}>
            <img
              className={styles.Element_Image}
              src={data?.languages?.publicURL || ""}
              alt="対応している言語やフレームワークのイメージ"
            />
            <div className={styles.Element_Text}>
              <div className={styles.Element_ItemHeadingNumber}>2</div>
              <h3 className={styles.Element_ItemHeading}>
                複数言語/フレームワークに対応
              </h3>
              <p className={styles.Element_Description}>
                <b>
                  堅牢化演習ではGo、Java、Ruby (Rails)をはじめとした6言語に対応
                </b>
                しており、実際の開発現場と近い環境でソースコードを修正する体験ができます。また、JWTやGraphQLなど、最新の技術スタックに合わせた学習も行えます。
              </p>
            </div>
          </div>
          <div className={styles.Element_Item}>
            <img
              className={styles.Element_Image}
              src={data?.flatt_image?.publicURL || ""}
              alt="Flatt Securityのイメージ"
            />
            <div className={styles.Element_Text}>
              <div className={styles.Element_ItemHeadingNumber}>3</div>
              <h3 className={styles.Element_ItemHeading}>
                実績豊富なセキュリティ企業が 作成したコンテンツ
              </h3>
              <p className={styles.Element_Description}>
                <b>
                  Flatt
                  Securityは、開発者のためのセキュリティ診断サービスを提供する企業
                </b>
                です。<Link to="/cve">多数の脆弱性報告の実績</Link>
                がある他、様々な業種のWebアプリケーションを診断した知見を活かして発信している
                <Link to="https://blog.flatt.tech">技術ブログ</Link>
                は多くの開発者に評価されています。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.CtaSection}>
        <a href="/kenro/download/service" className={styles.CtaSection_Link}>
          <Button
            buttonTheme="Kenro_Download"
            className={styles.Summary_Cta}
            type="button"
            buttonSize="Medium"
          >
            資料をダウンロード (無料)
          </Button>
        </a>
      </div>
      {/* <div className={styles.Trial}>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <div className={styles.Trial_Container}>
            <div className={styles.Trial_Item}>
              <div className={styles.Trial_Heading}>
                <span className={styles.Trial_HeadingSubText}>
                  # FREE TRIAL
                </span>
                実際のコンテンツの一部を
                <br />
                無料・無期限で試せます！
              </div>
              <p className={styles.Trial_ItemText}>
                サイドバーからKENROにご登録いただくことで、
                <br />
                すぐにコンテンツをお試しいただけます。
              </p>
            </div>
            <div className={styles.Trial_ItemImages}>
              <img
                className={styles.Trial_Image}
                src={data?.trial_form?.publicURL || ""}
                alt="トライアル申し込みフォームの画像"
              />
              <img
                className={styles.Trial_Image_ArrowIcon}
                src={data?.arrow_green?.publicURL || ""}
                alt=""
              />
            </div>
          </div>
        </div>
      </div> */}

      <div className={styles.Content}>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <h2 className={styles.Content_Heading}>KENROで学べること</h2>
          <div>
            <div className={styles.Content_Wrapper}>
              <h3 className={styles.Content_Title}>
                脆弱性を学ぶためのコンテンツ構成
              </h3>
              <div className={styles.Content_Images}>
                <img
                  className={styles.Content_TrainingImage}
                  src={data?.desk_study_image?.publicURL || ""}
                  alt="技術や脆弱性の解説のイメージ"
                />
                <img
                  className={styles.Content_Arrow}
                  src={data?.arrow?.publicURL || ""}
                  alt=""
                />
                <img
                  className={styles.Content_TrainingImage}
                  src={data?.hacking_like_training_image?.publicURL || ""}
                  alt="ハッキング演習のイメージ"
                />
                <img
                  className={styles.Content_Arrow}
                  src={data?.arrow?.publicURL || ""}
                  alt=""
                />
                <img
                  className={styles.Content_TrainingImage}
                  src={data?.hardening_training_image?.publicURL || ""}
                  alt="堅牢化演習のイメージ"
                />
              </div>
              <p className={styles.Content_Description}>
                技術や脆弱性の体系的な知識の習得から、ソースコードの修正まで、ワンストップで学習することができます。
                <br />
                CTF形式の攻撃訓練だけでなく、開発現場に活かせるソースコードの修正方法の学習が可能です。
              </p>
            </div>
            <div className={styles.Content_Wrapper}>
              <h3 className={styles.Content_Title}>
                基礎的な脆弱性に関するコンテンツ
              </h3>
              <p className={styles.Content_Description}>
                OWASP Top 10
                中に含まれる脆弱性をはじめ、Webアプリケーションにおける代表的な脆弱性を網羅的に学ぶことが可能です。
              </p>
              <ul className={styles.Content_List}>
                <li className={styles.Content_Item}>SQL Injection</li>
                <li className={styles.Content_Item}>
                  Cross-Site Scripting (XSS)
                </li>
                <li className={styles.Content_Item}>
                  Cross-Site Request Forgery (CSRF)
                </li>
                <li className={styles.Content_Item}>OS Command Injection</li>
                <li className={styles.Content_Item}>Directory Traversal</li>
                <li className={styles.Content_Item}>
                  Insecure Deserialization
                </li>
                <li className={styles.Content_Item}>
                  XML eXternal Entity (XXE)
                </li>
                <li className={styles.Content_Item}>Open Redirection</li>
                <li className={styles.Content_Item}>Clickjacking</li>
                <li className={styles.Content_Item}>Header Injection</li>
              </ul>
            </div>
            <div className={styles.Content_Wrapper}>
              <h3 className={styles.Content_Title}>発展的なコンテンツ</h3>
              <p className={styles.Content_Description}>
                ご要望に応じてコンテンツを随時追加しています。
              </p>
              <ul className={styles.Content_List}>
                <li className={styles.Content_Item}>
                  GraphQLの基礎とセキュリティ
                </li>
                <li className={styles.Content_Item}>JWTの基礎とセキュリティ</li>
                <li className={styles.Content_Item}>
                  WebSocketの基礎とセキュリティ
                </li>
              </ul>
            </div>
            <div className={styles.Content_Wrapper}>
              <h3 className={styles.Content_Title}>対応プログラミング言語</h3>
              <p className={styles.Content_Description}>
                基礎的な脆弱性に関するコンテンツの「堅牢化演習」にて対応しているプログラミング言語です。ご要望に応じて随時追加しています。
              </p>
              <ul className={styles.Content_List}>
                <li className={styles.Content_Item}>Java</li>
                <li className={styles.Content_Item}>Go</li>
                <li className={styles.Content_Item}>Python</li>
                <li className={styles.Content_Item}>Ruby (Rails)</li>
                <li className={styles.Content_Item}>PHP</li>
                <li className={styles.Content_Item}>C&#035;</li>
              </ul>
            </div>
            <div className={styles.Admin}>
              <div className={styles.KenroCommon2PaneSectionLayout}>
                <h2 className={styles.Admin_Heading}>
                  受講管理システムも標準搭載
                </h2>
                <div className={styles.Admin_Challenges}>
                  <div className={styles.Admin_ChallengeItem}>
                    <img
                      className={styles.Admin_Image}
                      src={data?.admin1?.publicURL || ""}
                      alt="受講者のグループ管理機能の画像"
                    />
                    <h3 className={styles.Admin_Title}>受講者のグループ管理</h3>
                    <p className={styles.Admin_Description}>
                      グループごとに受講状況を管理したり、CSVファイルを用いた一括登録・更新をしたりすることが可能です。
                    </p>
                  </div>
                  <div className={styles.Admin_ChallengeItem}>
                    <img
                      className={styles.Admin_Image}
                      src={data?.admin2?.publicURL || ""}
                      alt="受講のリマインド通知機能の画像"
                    />
                    <h3 className={styles.Admin_Title}>受講のリマインド通知</h3>
                    <p className={styles.Admin_Description}>
                      受講完了予定日を設定し、完了していない受講者にリマインドのためのメール通知を自動で送ることができます。
                    </p>
                  </div>
                  <div className={styles.Admin_ChallengeItem}>
                    <img
                      className={styles.Admin_Image}
                      src={data?.admin3?.publicURL || ""}
                      alt="進捗管理・成績分析機能の画像"
                    />
                    <h3 className={styles.Admin_Title}>進捗管理・成績分析</h3>
                    <p className={styles.Admin_Description}>
                      演習の進捗状況をグラフで把握することができ、演習の正答状況のデータを出力して分析できます。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Kenro2PaneLayout>
  );
};

const Container: React.FC<Props> = (props) => {
  return (
    <>
      <Component {...props} />
    </>
  );
};

export const query = graphql`
  query KenroNewGradsTrainingPage {
    hacking_like_training_image: file(
      relativePath: {
        eq: "kenro/new_grads_training/hacking_like_training_icon.svg"
      }
    ) {
      publicURL
    }
    hardening_training_image: file(
      relativePath: {
        eq: "kenro/new_grads_training/hardening_training_icon.svg"
      }
    ) {
      publicURL
    }
    desk_study_image: file(
      relativePath: { eq: "kenro/new_grads_training/desk_study.svg" }
    ) {
      publicURL
    }
    arrow: file(relativePath: { eq: "kenro/new_grads_training/arrow.svg" }) {
      publicURL
    }
    trial_form: file(
      relativePath: { eq: "kenro/new_grads_training/trial_form.svg" }
    ) {
      publicURL
    }
    arrow_green: file(
      relativePath: { eq: "kenro/new_grads_training/arrow_green.svg" }
    ) {
      publicURL
    }
    trial_form: file(
      relativePath: { eq: "kenro/new_grads_training/trial_form.svg" }
    ) {
      publicURL
    }
    languages: file(
      relativePath: { eq: "kenro/new_grads_training/languages.svg" }
    ) {
      publicURL
    }
    flatt_image: file(
      relativePath: { eq: "kenro/new_grads_training/flatt_image.svg" }
    ) {
      publicURL
    }
    education: file(
      relativePath: { eq: "kenro/new_grads_training/education.svg" }
    ) {
      publicURL
    }
    rocket: file(relativePath: { eq: "kenro/new_grads_training/rocket.svg" }) {
      publicURL
    }
    defense_training: file(
      relativePath: { eq: "kenro/new_grads_training/defense_training.svg" }
    ) {
      publicURL
    }
    hacking_like_training: file(
      relativePath: { eq: "kenro/new_grads_training/hacking_like_training.svg" }
    ) {
      publicURL
    }
    codespace: file(
      relativePath: { eq: "kenro/new_grads_training/codespace.svg" }
    ) {
      publicURL
    }
    initial_cost_zero: file(
      relativePath: { eq: "kenro/new_grads_training/initial_cost_zero.svg" }
    ) {
      publicURL
    }
    firstview_mock: file(
      relativePath: { eq: "kenro/new_grads_training/mock.svg" }
    ) {
      publicURL
    }
    admin1: file(relativePath: { eq: "kenro/admin1.svg" }) {
      publicURL
    }
    admin2: file(relativePath: { eq: "kenro/admin2.svg" }) {
      publicURL
    }
    admin3: file(relativePath: { eq: "kenro/admin3.svg" }) {
      publicURL
    }
    feature: file(relativePath: { eq: "kenro/feature.svg" }) {
      publicURL
    }
    hacking: file(relativePath: { eq: "kenro/hacking.svg" }) {
      publicURL
    }
    text: file(relativePath: { eq: "kenro/text.svg" }) {
      publicURL
    }
    ogp: file(relativePath: { eq: "kenro/ogp.png" }) {
      publicURL
    }
  }
`;

export default Container;
