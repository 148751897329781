import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";

import * as styles from "./index.module.scss";

type Props = {
  type?: "new-grads-training" | "six" | "twelve" | "full";
};

export const KenroAchievements: React.FC<Props> = (props) => {
  const data: GatsbyKenroAchievementsOrganismComponentQuery = useStaticQuery(
    graphql`
      query KenroAchievementsOrganismComponent {
        athome: file(
          relativePath: { eq: "kenro/achievement/athome.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        chatwork: file(
          relativePath: { eq: "kenro/achievement/chatwork.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        classi: file(
          relativePath: { eq: "kenro/achievement/classi.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        cyberagent: file(
          relativePath: { eq: "kenro/achievement/cyberagent.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        cygames: file(
          relativePath: { eq: "kenro/achievement/cygames.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        dinoscorporation: file(
          relativePath: { eq: "kenro/achievement/dinoscorporation.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        eureka: file(
          relativePath: { eq: "kenro/achievement/eureka.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        fujimi: file(
          relativePath: { eq: "kenro/achievement/fujimi.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        gmo_pepabo: file(
          relativePath: { eq: "kenro/achievement/gmo_pepabo.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        infcurion: file(
          relativePath: { eq: "kenro/achievement/infcurion.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        legalforce: file(
          relativePath: { eq: "kenro/achievement/legalforce.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        livesense: file(
          relativePath: { eq: "kenro/achievement/livesense.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        mdis: file(
          relativePath: { eq: "kenro/achievement/mdis.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        nttdata: file(
          relativePath: { eq: "kenro/achievement/nttdata.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        nikkei: file(
          relativePath: { eq: "kenro/achievement/nikkei.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        prtimes: file(
          relativePath: { eq: "kenro/achievement/prtimes.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        pixiv: file(
          relativePath: { eq: "kenro/achievement/pixiv.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        plus_alpha: file(
          relativePath: { eq: "kenro/achievement/plus_alpha_consulting.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        quocard: file(
          relativePath: { eq: "kenro/achievement/quocard.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        route06: file(
          relativePath: { eq: "kenro/achievement/route06.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        sakura_internet: file(
          relativePath: { eq: "kenro/achievement/sakura_internet.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        superstudio: file(
          relativePath: { eq: "kenro/achievement/superstudio.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        slc: file(
          relativePath: { eq: "kenro/achievement/slc.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        wantedly: file(
          relativePath: { eq: "kenro/achievement/wantedly.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        yrglm: file(
          relativePath: { eq: "kenro/achievement/yrglm.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `,
  );
  const { type } = props;
  switch (type) {
    case "six":
      return (
        <div className={styles.Achievements}>
          <div className={styles.Achievement_Grid}>
            <div className={styles.Achievement_ItemShadow__Partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.cyberagent}
                alt="サイバーエージェント"
              />
            </div>
            <div className={styles.Achievement_ItemShadow__Partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.athome}
                alt="アットホーム"
              />
            </div>
            <div className={styles.Achievement_ItemShadow__Partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.wantedly}
                alt="ウォンテッドリー"
              />
            </div>
            <div className={styles.Achievement_ItemShadow__Partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.nttdata}
                alt="NTTデータ ユニバーシティ"
              />
            </div>
            <div className={styles.Achievement_ItemShadow__Partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.cygames}
                alt="cygames"
              />
            </div>
            <div className={styles.Achievement_ItemShadow__Partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.sakura_internet}
                alt="さくらインターネット"
              />
            </div>
          </div>
        </div>
      );
    case "twelve":
      return (
        <div className={styles.Achievements}>
          <div className={styles.Achievement_Grid}>
            <div className={styles.Achievement_ItemShadow__Partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.gmo_pepabo}
                alt="GMOペパボ"
              />
            </div>
            <div className={styles.Achievement_ItemShadow__Partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.chatwork}
                alt="Chatwork"
              />
            </div>
            <div className={styles.Achievement_ItemShadow__Partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.nikkei}
                alt="日本経済新聞社"
              />
            </div>
            <div className={styles.Achievement_ItemShadow__Partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.pixiv}
                alt="ピクシブ"
              />
            </div>

            <div className={styles.Achievement_ItemShadow__Partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.plus_alpha}
                alt="プラスアルファ・コンサルティング"
              />
            </div>
            <div className={styles.Achievement_ItemShadow__Partial}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.mdis}
                alt="三菱電機インフォメーションシステムズ"
              />
            </div>
          </div>
        </div>
      );
    case "new-grads-training":
      return (
        <div className={styles.Achievements}>
          <div className={styles.Achievement_Grid}>
            <div className={styles.Achievement_Item}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.cyberagent}
                alt="サイバーエージェント"
              />
            </div>
            <div className={styles.Achievement_Item}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.athome}
                alt="アットホーム"
              />
            </div>

            <div className={styles.Achievement_Item}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.wantedly}
                alt="ウォンテッドリー"
              />
            </div>
            <div className={styles.Achievement_Item}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.nttdata}
                alt="NTTデータ ユニバーシティ"
              />
            </div>
            <div className={styles.Achievement_Item}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.quocard}
                alt="クオカード"
              />
            </div>
            <div className={styles.Achievement_Item}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.gmo_pepabo}
                alt="GMOペパボ"
              />
            </div>
            <div className={styles.Achievement_Item}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.slc}
                alt="スミセイ情報システム"
              />
            </div>

            <div className={styles.Achievement_Item}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.chatwork}
                alt="Chatwork"
              />
            </div>
            <div className={styles.Achievement_Item}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.nikkei}
                alt="日本経済新聞社"
              />
            </div>
            <div className={styles.Achievement_Item}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.pixiv}
                alt="ピクシブ"
              />
            </div>
            <div className={styles.Achievement_Item}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.mdis}
                alt="三菱電機インフォメーションシステムズ"
              />
            </div>
            <div className={styles.Achievement_Item}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.legalforce}
                alt="LegalForce"
              />
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div className={styles.Achievements}>
          <div className={styles.Achievement_Grid}>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.cyberagent}
                alt="サイバーエージェント"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.athome}
                alt="アットホーム"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.yrglm}
                alt="イルグルム"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.infcurion}
                alt="インフキュリオン"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.wantedly}
                alt="ウォンテッドリー"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.eureka}
                alt="エウレカ"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.nttdata}
                alt="NTTデータ ユニバーシティ"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.quocard}
                alt="クオカード"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.classi}
                alt="クラッシー"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.sakura_internet}
                alt="さくらインターネット"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.cygames}
                alt="Cygames"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.gmo_pepabo}
                alt="GMOペパボ"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.superstudio}
                alt="SUPER STUDIO"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.slc}
                alt="スミセイ情報システム"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.chatwork}
                alt="Chatwork"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.dinoscorporation}
                alt="DINOS CORPORATION"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.nikkei}
                alt="日本経済新聞社"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.pixiv}
                alt="ピクシブ"
              />
            </div>

            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.fujimi}
                alt="フジミ"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.plus_alpha}
                alt="プラスアルファ・コンサルティング"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.mdis}
                alt="三菱電機インフォメーションシステムズ"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.legalforce}
                alt="LegalForce"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.livesense}
                alt="リブセンス"
              />
            </div>
            <div className={styles.Achievement_ItemShadow}>
              <OptionalGatsbyImage
                className={styles.Achievement_Image}
                data={data.route06}
                alt="ROUTE06"
              />
            </div>
          </div>
        </div>
      );
  }
};
