// extracted by mini-css-extract-plugin
export var Achievement = "index-module--Achievement--JNbLw";
export var Admin = "index-module--Admin--7xXtW";
export var Admin_Bold = "index-module--Admin_Bold--v5z8i";
export var Admin_ChallengeItem = "index-module--Admin_ChallengeItem--ZUrzm";
export var Admin_Challenges = "index-module--Admin_Challenges--Y+Sah";
export var Admin_Description = "index-module--Admin_Description--JfXT+";
export var Admin_Heading = "index-module--Admin_Heading--6utDR";
export var Admin_Image = "index-module--Admin_Image--Nlfue";
export var Admin_Patent = "index-module--Admin_Patent---10Ll";
export var Admin_Title = "index-module--Admin_Title--OjhXl";
export var Content = "index-module--Content--YJSNZ";
export var Content_Annotation = "index-module--Content_Annotation--aL7PY";
export var Content_Arrow = "index-module--Content_Arrow--tOX0X";
export var Content_Description = "index-module--Content_Description--s60Ok";
export var Content_Heading = "index-module--Content_Heading--drKjw";
export var Content_Images = "index-module--Content_Images--Z1VL6";
export var Content_Item = "index-module--Content_Item--+zvur";
export var Content_List = "index-module--Content_List--PYvZF";
export var Content_Title = "index-module--Content_Title---3Poy";
export var Content_TrainingImage = "index-module--Content_TrainingImage--YDkFa";
export var Content_Wrapper = "index-module--Content_Wrapper--hYX5C";
export var CtaSection = "index-module--CtaSection--k6olH";
export var Element = "index-module--Element--jkt1z";
export var Element_Description = "index-module--Element_Description--70Ejx";
export var Element_Green = "index-module--Element_Green--1wn6d";
export var Element_Heading = "index-module--Element_Heading--ahs0Q";
export var Element_Image = "index-module--Element_Image--ptaMn";
export var Element_Item = "index-module--Element_Item--G7I7h";
export var Element_ItemHeading = "index-module--Element_ItemHeading--r2VIy";
export var Element_ItemHeadingNumber = "index-module--Element_ItemHeadingNumber--4JQnC";
export var Element_ItemReverse = "index-module--Element_ItemReverse--euJRC";
export var Element_NewLine = "index-module--Element_NewLine--Qtxe1";
export var Element_Text = "index-module--Element_Text--Gv-uU";
export var KenroCommon2PaneSectionLayout = "index-module--KenroCommon2PaneSectionLayout--Zt6tv";
export var KeyFeature = "index-module--KeyFeature--BsUgq";
export var KeyFeature_Container = "index-module--KeyFeature_Container--9V-HZ";
export var KeyFeature_Description = "index-module--KeyFeature_Description--iqNua";
export var KeyFeature_Heading = "index-module--KeyFeature_Heading--kyRXe";
export var KeyFeature_Image = "index-module--KeyFeature_Image--838Gh";
export var KeyFeature_Item = "index-module--KeyFeature_Item--YGSyv";
export var KeyFeature_ItemHeading = "index-module--KeyFeature_ItemHeading---rZ9R";
export var KeyFeature_ItemIcons = "index-module--KeyFeature_ItemIcons--qztFx";
export var KeyFeature_Text = "index-module--KeyFeature_Text--QJ8fp";
export var Summary = "index-module--Summary--MhOng";
export var Summary_Container = "index-module--Summary_Container--GP4AG";
export var Summary_Cta = "index-module--Summary_Cta--fH47O";
export var Summary_Description = "index-module--Summary_Description--H5Lkl";
export var Summary_Green = "index-module--Summary_Green--7QqM3";
export var Summary_Heading = "index-module--Summary_Heading--c66cq";
export var Summary_HeadingLogo = "index-module--Summary_HeadingLogo--b6gkx";
export var Summary_HeadingNewLine = "index-module--Summary_HeadingNewLine--K7UoQ";
export var Summary_Left = "index-module--Summary_Left--ZZmMn";
export var Summary_Mock = "index-module--Summary_Mock--R5mLr";
export var Summary_Right = "index-module--Summary_Right--xqdRp";
export var Trial = "index-module--Trial--9OX9g";
export var Trial_Container = "index-module--Trial_Container--m0lPV";
export var Trial_Description = "index-module--Trial_Description--tcNNH";
export var Trial_Heading = "index-module--Trial_Heading--+mFks";
export var Trial_HeadingSubText = "index-module--Trial_HeadingSubText--NLnAe";
export var Trial_Image = "index-module--Trial_Image--7kY0N";
export var Trial_Image_ArrowIcon = "index-module--Trial_Image_ArrowIcon--p1voE";
export var Trial_Item = "index-module--Trial_Item--ivD1G";
export var Trial_ItemImages = "index-module--Trial_ItemImages--Ik3ov";
export var Trial_ItemText = "index-module--Trial_ItemText--tNlo4";
export var UseCase = "index-module--UseCase--DiJsp";
export var UseCase_Annotation = "index-module--UseCase_Annotation--9TR66";
export var UseCase_Container = "index-module--UseCase_Container--ApWhZ";
export var UseCase_Description = "index-module--UseCase_Description--95WmQ";
export var UseCase_Green = "index-module--UseCase_Green--ehfzi";
export var UseCase_Heading = "index-module--UseCase_Heading--EyadU";
export var UseCase_HeadingDescription = "index-module--UseCase_HeadingDescription--9FLAl";
export var UseCase_Image = "index-module--UseCase_Image--nl11e";
export var UseCase_Item = "index-module--UseCase_Item--3QB5T";
export var UseCase_ItemHeading = "index-module--UseCase_ItemHeading--4koMJ";
export var UseCase_ItemHeadingSubText = "index-module--UseCase_ItemHeadingSubText--QDXk5";
export var UseCase_ItemIcons = "index-module--UseCase_ItemIcons--0Drzt";
export var UseCase_Text = "index-module--UseCase_Text--SAsX2";
export var Voice = "index-module--Voice--mubk8";
export var Voice_About = "index-module--Voice_About--sdwZm";
export var Voice_AvatarWrapper = "index-module--Voice_AvatarWrapper--Zx+CJ";
export var Voice_Card = "index-module--Voice_Card--ysMN7";
export var Voice_Container = "index-module--Voice_Container--+TmHw";
export var Voice_Position = "index-module--Voice_Position--6mvri";
export var Voice_Tag = "index-module--Voice_Tag--C+bwp";
export var Voice_Title = "index-module--Voice_Title--1Ilx9";